// extracted by mini-css-extract-plugin
export var awesome = "styles-module--awesome--f4755";
export var bold = "styles-module--bold--fa020";
export var bottomButton = "styles-module--bottom-button--71518";
export var button = "styles-module--button--8ae9e";
export var buttonWrapper = "styles-module--button-wrapper--0653c";
export var center = "styles-module--center--9d840";
export var complete = "styles-module--complete--5d678";
export var disabled = "styles-module--disabled--8b66b";
export var h1 = "styles-module--h1--e12cc";
export var h1Bold = "styles-module--h1--bold--edeed";
export var h1Uc = "styles-module--h1--uc--d9850";
export var h2 = "styles-module--h2--4bc56";
export var h2Bold = "styles-module--h2--bold--9c2f6";
export var h2Uc = "styles-module--h2--uc--c8012";
export var h3 = "styles-module--h3--f1abd";
export var h3Bold = "styles-module--h3--bold--967bf";
export var h3Uc = "styles-module--h3--uc--e1c94";
export var h4 = "styles-module--h4--193f6";
export var h4Bold = "styles-module--h4--bold--46c34";
export var h4Uc = "styles-module--h4--uc--a2891";
export var linkUnderline = "styles-module--link--underline--7f254";
export var microtext = "styles-module--microtext--120f8";
export var microtextBold = "styles-module--microtext--bold--d1178";
export var microtextUc = "styles-module--microtext--uc--edd35";
export var nanotext = "styles-module--nanotext--5a951";
export var strikethrough = "styles-module--strikethrough--e8b36";
export var strikethroughBold = "styles-module--strikethrough--bold--e0b5e";
export var strikethroughLg = "styles-module--strikethrough--lg--75033";
export var strikethroughSm = "styles-module--strikethrough--sm--ad1a8";
export var subtext = "styles-module--subtext--12b1e";
export var subtextBold = "styles-module--subtext--bold--2227c";
export var subtextUc = "styles-module--subtext--uc--ca268";
export var text = "styles-module--text--c4e32";
export var textBold = "styles-module--text--bold--78d2f";
export var textUc = "styles-module--text--uc--1df49";
export var titleL = "styles-module--title-l--a5a4d";
export var titleM = "styles-module--title-m--79cb3";
export var titleS = "styles-module--title-s--51ae5";
export var titleXl = "styles-module--title-xl--cdef8";
export var titleXs = "styles-module--title-xs--884af";
export var titleXxs = "styles-module--title-xxs--42e55";
export var uc = "styles-module--uc--8e08f";
export var underline = "styles-module--underline--fce2b";