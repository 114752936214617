// extracted by mini-css-extract-plugin
export var active = "styles-module--active--81914";
export var awesome = "styles-module--awesome--23e4d";
export var bold = "styles-module--bold--ec587";
export var center = "styles-module--center--9c58a";
export var h1 = "styles-module--h1--c6e37";
export var h1Bold = "styles-module--h1--bold--801f9";
export var h1Uc = "styles-module--h1--uc--e3348";
export var h2 = "styles-module--h2--6f39e";
export var h2Bold = "styles-module--h2--bold--fd0af";
export var h2Uc = "styles-module--h2--uc--818ad";
export var h3 = "styles-module--h3--2eff0";
export var h3Bold = "styles-module--h3--bold--8db9e";
export var h3Uc = "styles-module--h3--uc--e9f0e";
export var h4 = "styles-module--h4--aa915";
export var h4Bold = "styles-module--h4--bold--7351e";
export var h4Uc = "styles-module--h4--uc--ed4db";
export var linkUnderline = "styles-module--link--underline--f118b";
export var microtext = "styles-module--microtext--1a66b";
export var microtextBold = "styles-module--microtext--bold--56f00";
export var microtextUc = "styles-module--microtext--uc--49960";
export var nanotext = "styles-module--nanotext--f694b";
export var stepsDetails = "styles-module--steps-details--7879a";
export var stepsDetails__leftContainer = "styles-module--steps-details__left-container--126e6";
export var stepsDetails__rightContainer = "styles-module--steps-details__right-container--21a86";
export var strikethrough = "styles-module--strikethrough--ae542";
export var strikethroughBold = "styles-module--strikethrough--bold--f9dfe";
export var strikethroughLg = "styles-module--strikethrough--lg--da976";
export var strikethroughSm = "styles-module--strikethrough--sm--5404f";
export var subtext = "styles-module--subtext--0fcb0";
export var subtextBold = "styles-module--subtext--bold--b70b3";
export var subtextUc = "styles-module--subtext--uc--8fd40";
export var text = "styles-module--text--0e9a4";
export var textBold = "styles-module--text--bold--7588f";
export var textUc = "styles-module--text--uc--718ef";
export var titleL = "styles-module--title-l--cca19";
export var titleM = "styles-module--title-m--23ceb";
export var titleS = "styles-module--title-s--180b2";
export var titleXl = "styles-module--title-xl--dec83";
export var titleXs = "styles-module--title-xs--ac88c";
export var titleXxs = "styles-module--title-xxs--d3898";
export var uc = "styles-module--uc--c1818";
export var underline = "styles-module--underline--45eb9";