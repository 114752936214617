// extracted by mini-css-extract-plugin
export var active = "styles-module--active--94aef";
export var awesome = "styles-module--awesome--85702";
export var bold = "styles-module--bold--85b09";
export var center = "styles-module--center--4dab1";
export var h1 = "styles-module--h1--c5744";
export var h1Bold = "styles-module--h1--bold--13045";
export var h1Uc = "styles-module--h1--uc--ca2e4";
export var h2 = "styles-module--h2--772a1";
export var h2Bold = "styles-module--h2--bold--51c18";
export var h2Uc = "styles-module--h2--uc--92f4f";
export var h3 = "styles-module--h3--59ca7";
export var h3Bold = "styles-module--h3--bold--df85b";
export var h3Uc = "styles-module--h3--uc--2df40";
export var h4 = "styles-module--h4--9da32";
export var h4Bold = "styles-module--h4--bold--35e45";
export var h4Uc = "styles-module--h4--uc--5bd81";
export var linkUnderline = "styles-module--link--underline--5fd9a";
export var microtext = "styles-module--microtext--b65c3";
export var microtextBold = "styles-module--microtext--bold--c21d1";
export var microtextUc = "styles-module--microtext--uc--3f401";
export var nanotext = "styles-module--nanotext--c3028";
export var stepsDetails = "styles-module--steps-details--80e3a";
export var stepsDetails__leftContainer = "styles-module--steps-details__left-container--3bd63";
export var stepsDetails__rightContainer = "styles-module--steps-details__right-container--f3880";
export var strikethrough = "styles-module--strikethrough--b4043";
export var strikethroughBold = "styles-module--strikethrough--bold--ea829";
export var strikethroughLg = "styles-module--strikethrough--lg--37c78";
export var strikethroughSm = "styles-module--strikethrough--sm--84e38";
export var subtext = "styles-module--subtext--22874";
export var subtextBold = "styles-module--subtext--bold--cb9c4";
export var subtextUc = "styles-module--subtext--uc--a6f83";
export var text = "styles-module--text--40008";
export var textBold = "styles-module--text--bold--ffd89";
export var textUc = "styles-module--text--uc--939ef";
export var titleL = "styles-module--title-l--cd251";
export var titleM = "styles-module--title-m--7041a";
export var titleS = "styles-module--title-s--ce377";
export var titleXl = "styles-module--title-xl--34ea3";
export var titleXs = "styles-module--title-xs--8d4cc";
export var titleXxs = "styles-module--title-xxs--e8e39";
export var uc = "styles-module--uc--f41fd";
export var underline = "styles-module--underline--1d5a2";