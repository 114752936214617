// extracted by mini-css-extract-plugin
export var active = "styles-module--active--8c219";
export var awesome = "styles-module--awesome--72c22";
export var bold = "styles-module--bold--0b2f5";
export var center = "styles-module--center--f09fe";
export var h1 = "styles-module--h1--ac488";
export var h1Bold = "styles-module--h1--bold--09361";
export var h1Uc = "styles-module--h1--uc--975e3";
export var h2 = "styles-module--h2--f3257";
export var h2Bold = "styles-module--h2--bold--33734";
export var h2Uc = "styles-module--h2--uc--6abb8";
export var h3 = "styles-module--h3--985ac";
export var h3Bold = "styles-module--h3--bold--9b235";
export var h3Uc = "styles-module--h3--uc--6be8f";
export var h4 = "styles-module--h4--bca20";
export var h4Bold = "styles-module--h4--bold--43d0d";
export var h4Uc = "styles-module--h4--uc--5adad";
export var link = "styles-module--link--3dfec";
export var linkContainer = "styles-module--link-container--1b04a";
export var linkUnderline = "styles-module--link--underline--cf102";
export var luxe = "styles-module--luxe--cdccb";
export var microtext = "styles-module--microtext--95bea";
export var microtextBold = "styles-module--microtext--bold--2ed97";
export var microtextUc = "styles-module--microtext--uc--32d4d";
export var nanotext = "styles-module--nanotext--76b32";
export var strikethrough = "styles-module--strikethrough--e4932";
export var strikethroughBold = "styles-module--strikethrough--bold--aada9";
export var strikethroughLg = "styles-module--strikethrough--lg--74ccb";
export var strikethroughSm = "styles-module--strikethrough--sm--b7a90";
export var subtext = "styles-module--subtext--b012b";
export var subtextBold = "styles-module--subtext--bold--ac3a4";
export var subtextUc = "styles-module--subtext--uc--20a62";
export var text = "styles-module--text--33235";
export var textBold = "styles-module--text--bold--16a45";
export var textUc = "styles-module--text--uc--02423";
export var titleL = "styles-module--title-l--513eb";
export var titleM = "styles-module--title-m--4be16";
export var titleS = "styles-module--title-s--4736a";
export var titleXl = "styles-module--title-xl--40beb";
export var titleXs = "styles-module--title-xs--1952a";
export var titleXxs = "styles-module--title-xxs--cd469";
export var uc = "styles-module--uc--4e9db";
export var underline = "styles-module--underline--0a51f";