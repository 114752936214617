// extracted by mini-css-extract-plugin
export var active = "styles-module--active--658a2";
export var awesome = "styles-module--awesome--220b4";
export var bold = "styles-module--bold--ace04";
export var center = "styles-module--center--3fbf6";
export var h1 = "styles-module--h1--14d8f";
export var h1Bold = "styles-module--h1--bold--552ea";
export var h1Uc = "styles-module--h1--uc--54088";
export var h2 = "styles-module--h2--94dd5";
export var h2Bold = "styles-module--h2--bold--f7629";
export var h2Uc = "styles-module--h2--uc--db8ba";
export var h3 = "styles-module--h3--43158";
export var h3Bold = "styles-module--h3--bold--4aa29";
export var h3Uc = "styles-module--h3--uc--bc760";
export var h4 = "styles-module--h4--663a5";
export var h4Bold = "styles-module--h4--bold--a2541";
export var h4Uc = "styles-module--h4--uc--be6b7";
export var incomplete = "styles-module--incomplete--0e8bb";
export var linkUnderline = "styles-module--link--underline--b2bf1";
export var microtext = "styles-module--microtext--883bc";
export var microtextBold = "styles-module--microtext--bold--72273";
export var microtextUc = "styles-module--microtext--uc--c2f54";
export var nanotext = "styles-module--nanotext--c382c";
export var strikethrough = "styles-module--strikethrough--67db7";
export var strikethroughBold = "styles-module--strikethrough--bold--44927";
export var strikethroughLg = "styles-module--strikethrough--lg--1c915";
export var strikethroughSm = "styles-module--strikethrough--sm--9fccc";
export var subtext = "styles-module--subtext--ebc91";
export var subtextBold = "styles-module--subtext--bold--4c1d3";
export var subtextUc = "styles-module--subtext--uc--b24fe";
export var subtitleStep = "styles-module--subtitle-step--5f497";
export var subtitlesHeading = "styles-module--subtitles-heading--8ac24";
export var text = "styles-module--text--f950c";
export var textBold = "styles-module--text--bold--4cb34";
export var textUc = "styles-module--text--uc--c0f5e";
export var titleL = "styles-module--title-l--ae38e";
export var titleM = "styles-module--title-m--48169";
export var titleS = "styles-module--title-s--5893a";
export var titleXl = "styles-module--title-xl--0af05";
export var titleXs = "styles-module--title-xs--f5039";
export var titleXxs = "styles-module--title-xxs--1c786";
export var uc = "styles-module--uc--bef14";
export var underline = "styles-module--underline--a1a9e";