import PropTypes from "prop-types";
import ArrowIcon from "../icons/svgs/arrow.svg";

function StaticPagination({
  page,
  itemsPerPage,
  itemsCount,
  handlePageChange = false,
  className = null,
  showNextPrev = false,
}) {
  const pageCount = Math.ceil(itemsCount / itemsPerPage);

  // desired result: pageDecades = [0, 10, 20, ...]
  const pageDecades = Array.from(
    { length: Math.max(Math.ceil(pageCount / 10), 1) },
    (_, i) => i * 10
  );

  // desired result: pages = [1, 10, 20, 21, 22, 23, ... 29, 30, 40] if page >= 20 and page < 30
  let pages = [];
  pageDecades.forEach((d) => {
    if (page >= d && page < d + 10) {
      const expandedDecade = Array.from(
        { length: Math.min(10, pageCount - d) },
        (_, i) => i + d + 1
      );

      pages = [...pages, ...expandedDecade];
    } else if (pages.indexOf(d || 1) === -1) {
      pages.push(d || 1);
    }
  });

  if (handlePageChange) {
    return (
      <div className={`static__pagination ${className}`}>
        {page > 1 && showNextPrev && (
          <a
            href={`?page=${page - 1}`}
            rel="prev"
            style={{
              width: "12px",
              height: "12px",
              display: "flex",
            }}
          >
            <ArrowIcon className="" />
          </a>
        )}
        {pages.map((p) => (
          <span
            key={`pagination_${p}`}
            onClick={() => handlePageChange(p)}
            className={`${p === page && "link--underline"} pr-5`}
            style={{ cursor: "pointer" }}
          >
            {p}
          </span>
        ))}
        {pageCount > 1 && showNextPrev && (
          <a
            href={`?page=${page + 1}`}
            rel="next"
            style={{
              width: "12px",
              height: "12px",
              display: "flex",
              transform: "rotate(180deg)",
            }}
          >
            <ArrowIcon />
          </a>
        )}
      </div>
    );
  }

  return (
    <div className={`static__pagination ${className}`}>
      {page > 1 && (
        <span className="link--underline pr-5">
          <a className="static__pagination--prev" href={`?page=${page - 1}`}>
            Previous
          </a>
        </span>
      )}
      {pages.map((p) => (
        <a key={`pagination_${p}`} href={`?page=${p}`} className="link--underline pr-5">
          {p}
        </a>
      ))}
      {pageCount > 1 && (
        <span className="link--underline pr-5">
          <a className="static__pagination--next" href={`?page=${page + 1}`}>
            Next
          </a>
        </span>
      )}
    </div>
  );
}

StaticPagination.propTypes = {
  className: PropTypes.string,
  page: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  itemsCount: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func,
};

export default StaticPagination;
